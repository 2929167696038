import { z } from "zod";

export const possibleEnvs = z.enum(["production", "staging", "development"]);

const env = z.object({ BUILD_ENV: possibleEnvs });

export type PossibleEnvs = z.infer<typeof possibleEnvs>;

export const processEnv: Record<keyof z.infer<typeof env>, PossibleEnvs> = {
  BUILD_ENV: process.env.BUILD_ENV as PossibleEnvs,
};

export const wizardUrl: Record<PossibleEnvs, string> = {
  development:
    "https://staging-people-hros-web-recruitment-wizard.flashapp.site",
  staging: "https://staging-people-hros-web-recruitment-wizard.flashapp.site",
  production: "https://www.paginadevagas.com.br",
};

export const bffUrlMap: Record<PossibleEnvs, string> = {
  development: "http://localhost:3000/trpc",
  // development: "https://hros-web-recruitment-bff.private.flashapp.dev/trpc",
  staging: "https://hros-web-recruitment-bff.private.flashapp.dev/trpc",
  production: "https://hros-web-recruitment-bff.us.flashapp.services/trpc",
};

export const gatewayUrl: Record<PossibleEnvs, string> = {
  development: "https://people-private.private.flashapp.dev/recruitment/v1",
  staging: "https://people-private.private.flashapp.dev/recruitment/v1",
  production: "https://recruitment.us.flashapp.services",
  // production:
  //   "https://people-private.us.private.flashapp.services/recruitment/v1",
};
