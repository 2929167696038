import {
  boolean,
  route,
  string,
  union,
} from "react-router-typesafe-routes/dom";

const ROUTES = {
  JOB_OPENINGS: route(
    "recruitment/job-openings",
    {
      searchParams: {
        situation: union("open", "closed").default("open"),
        limit: string().default("10"),
        page: string().default("1"),
        query: string().default(""),
        status: string(),
        publishMethod: string(),
        deadline: string(),
        hasCloseDate: string(),
        publishedAtStart: string(),
        publishedAtEnd: string(),
        closeAtStart: string(),
        closeAtEnd: string(),
        departments: string(),
        recruiter: string(),
        manager: string(),
        isAffirmative: boolean(),
      },
    },
    {
      CREATE: route("create", {
        searchParams: {
          jobOpeningId: string(),
        },
      }),
      CLOSE: route("close", {
        searchParams: {
          jobOpeningId: string(),
        },
      }),
    },
  ),
  JOB_APPLICATION: route(
    "recruitment/job-applications",
    {
      searchParams: {
        jobOpeningId: string().default(""),
        situation: union("open", "closed").default("open"),
        query: string().default(""),
        ordination: string().default(""),
        languages: string(),
        cities: string(),
        degreeTypes: string(),
        experienceTimeFrom: string(),
        experienceTimeTo: string(),
        jobApplicationAtStart: string(),
        jobApplicationAtEnd: string(),
        skinColor: string(),
        gender: string(),
        disabilities: string(),
        keywords: string(),
      },
    },
    {
      PIPELINES: route("pipelines", {
        searchParams: {
          jobOpeningId: string().default(""),
          jobApplicationId: string().default(""),
          jobPipelineId: string().default(""),
        },
      }),
      EMAIL_TEMPLATE: route("email_template", {
        searchParams: {
          emailId: string().default(""),
        },
      }),
    },
  ),
  SETTINGS: route("recruitment/settings", {
    searchParams: {
      setting: union("logo", "banner", "about", "contact").default("logo"),
    },
  }),
};

export default ROUTES;
