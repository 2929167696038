import { CSSProperties, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
type BoxProps = {
  children?: React.ReactNode;
  flexDirection?: CSSProperties["flexDirection"];
  justifyContent?: CSSProperties["justifyContent"];
  alignItems?: CSSProperties["alignItems"];
  $px?: CSSProperties["padding"];
  $py?: CSSProperties["padding"];
  $pt?: CSSProperties["padding"];
  $pb?: CSSProperties["padding"];
  $pl?: CSSProperties["padding"];
  $pr?: CSSProperties["padding"];
  $mt?: CSSProperties["padding"];
  $mb?: CSSProperties["padding"];
  $ml?: CSSProperties["padding"];
  $mr?: CSSProperties["padding"];
  $mx?: CSSProperties["padding"];
  $my?: CSSProperties["padding"];
  $gap?: CSSProperties["gap"];
  $width?: CSSProperties["width"];
  $height?: CSSProperties["height"];
  flex?: CSSProperties["flex"];
  flexWrap?: CSSProperties["flexWrap"];
  as?: keyof JSX.IntrinsicElements;
  $borderRadius?: CSSProperties["borderRadius"];
  $border?: CSSProperties["border"];
  $borderTop?: CSSProperties["borderTop"];
  $cursor?: CSSProperties["cursor"];
} & HTMLAttributes<
  HTMLDivElement | HTMLSpanElement | HTMLLabelElement | HTMLButtonElement
>;

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}%;
    `}
  ${({ $height }) =>
    $height &&
    css`
      height: ${$height}%;
    `}
  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${$gap}px;
    `}
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
  ${({ $px }) =>
    $px &&
    css`
      padding-left: ${$px}px;
      padding-right: ${$px}px;
    `}
  ${({ $py }) =>
    $py &&
    css`
      padding-top: ${$py}px;
      padding-bottom: ${$py}px;
    `}
  ${({ $pt }) =>
    $pt &&
    css`
      padding-top: ${$pt}px;
    `}
  ${({ $pb }) =>
    $pb &&
    css`
      padding-bottom: ${$pb}px;
    `}
  ${({ $pl }) =>
    $pl &&
    css`
      padding-left: ${$pl}px;
    `}
  ${({ $pr }) =>
    $pr &&
    css`
      padding-right: ${$pr}px;
    `}
  ${({ $mt }) =>
    $mt &&
    css`
      margin-top: ${$mt}px;
    `}
  ${({ $mb }) =>
    $mb &&
    css`
      margin-bottom: ${$mb}px;
    `}
  ${({ $ml }) =>
    $ml &&
    css`
      margin-left: ${$ml}px;
    `}
  ${({ $mr }) =>
    $mr &&
    css`
      margin-right: ${$mr}px;
    `}
  ${({ $mx }) =>
    $mx &&
    css`
      margin-left: ${$mx}px;
      margin-right: ${$mx}px;
    `}
  ${({ $my }) =>
    $my &&
    css`
      margin-top: ${$my}px;
      margin-bottom: ${$my}px;
    `}
  ${({ $borderRadius }) =>
    $borderRadius &&
    css`
      border-radius: ${$borderRadius}px;
    `}
  ${({ $border }) =>
    $border &&
    css`
      border: ${$border};
    `}
    ${({ $borderTop }) =>
    $borderTop &&
    css`
      border-top: ${$borderTop};
    `}

  ${({ $cursor }) =>
    $cursor &&
    css`
      cursor: ${$cursor || "auto"};
    `}
`;

export const BoxCard = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.neutral20};
`;
