import { getFromLS } from "@flash-tecnologia/hros-web-utility";
import { processEnv } from "@utils/env";
import { Navigate, Outlet } from "react-router-dom";

const StagingRoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const isLoggedIn = getFromLS("hrosAccessToken");

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (processEnv.BUILD_ENV === "production") {
    return <Navigate to={"/home"} />;
  }

  return children ? children : <Outlet />;
};

export default StagingRoutesGuard;
