import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

import { ErrorBoundary } from "@utils/error-boundary";

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  renderType: "render",
  errorBoundaryClass: ErrorBoundary,
});
