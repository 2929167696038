import { Outlet, Navigate, matchPath, useLocation } from "react-router-dom";

import {
  useSelectedCompany,
  getFromLS,
  usePermissions,
} from "@flash-tecnologia/hros-web-utility";

import { flatMap } from "lodash";

import { useAuth, usePermissionsFlags } from "@flash-hros/auth-helper";
import ROUTES from "@utils/route-names";

export const checkUnleashPermission = (flagName: string) => {
  const flags = usePermissionsFlags.getState().flags;

  return flags?.some((_flag: any) => _flag.name === flagName);
};

export const RoutesGuard = ({ children }: { children?: JSX.Element }) => {
  const location = useLocation();

  const isLoggedIn = getFromLS("hrosAccessToken");

  const { selectedCompany } = useSelectedCompany();

  const allPermissions = usePermissions();

  const contractStatus = useAuth.getState().contractStatus || null;

  const companyPermissions = allPermissions?.companies?.find(
    (company) => company.id === selectedCompany.id,
  );

  const permissions = companyPermissions?.permissions || [];

  const slugRoutes = [
    {
      slug: "people_recruitment_list_job_openings",
      routes: [ROUTES.JOB_OPENINGS.path],
    },
    {
      slug: "people_recruitment_manage_job_openings",
      routes: [
        ROUTES.JOB_OPENINGS.path,
        ROUTES.JOB_APPLICATION.path,
        ROUTES.JOB_APPLICATION.PIPELINES.path,
        ROUTES.JOB_APPLICATION.EMAIL_TEMPLATE.path,
        ROUTES.JOB_OPENINGS.CLOSE.path,
        ROUTES.JOB_OPENINGS.CREATE.path,
        ROUTES.SETTINGS.path,
      ],
    },
  ];

  const routesBySlug = slugRoutes.filter((route) =>
    permissions.some(
      (permission) => permission === "*" || permission === route.slug,
    ),
  );

  const routes = flatMap(routesBySlug, "routes");

  const isRouteAllowed = routes.some((c) =>
    matchPath({ path: c, end: true }, location.pathname),
  );

  const hasUnleashPermission = checkUnleashPermission(
    "FLASH_OS_PEOPLE_RECRUITMENT",
  );

  if (!isLoggedIn) {
    return <Navigate to={"/authentication/login"} />;
  }

  if (!isRouteAllowed || !hasUnleashPermission) {
    return <Navigate to={"/home"} />;
  }

  // if (contractStatus?.url) {
  //   return <Navigate to={contractStatus?.url} state={{ from: location }} />;
  // }

  return children ? children : <Outlet />;
};
