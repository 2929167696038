import { trpc } from "@api/client";
import {
  getAccessToken,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import type { QueryClient } from "@tanstack/react-query";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import { bffUrlMap, processEnv } from "@utils/env";
import { AppRouter } from "backend";
import type { ReactNode } from "react";

const fetchFunction = async (
  url: RequestInfo | URL,
  options: RequestInit | undefined,
) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    if (response.status === 401) {
      new Error("Unauthorized");
    }
  }
  return response;
};

type TrpcClientCustomProps = {
  selectedCompany: ReturnType<typeof useSelectedCompany>["selectedCompany"];
};

export const trpcClient = ({ selectedCompany }: TrpcClientCustomProps) =>
  trpc.createClient({
    links: [
      httpBatchLink({
        url: bffUrlMap[processEnv.BUILD_ENV],
        async headers() {
          const accessToken = await getAccessToken();
          const xRequestId = Math.random().toString(36).substring(2, 15);
          return {
            authorization: accessToken,
            "x-request-id": xRequestId,
            "x-selected-company": selectedCompany?.id,
          };
        },
      }),
    ],
  });
export const trpcProxyClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: bffUrlMap[processEnv.BUILD_ENV],
      fetch: fetchFunction,
    }),
  ],
});

export function TrpcProvider({
  children,
  client,
}: {
  children: ReactNode;
  client: QueryClient;
}) {
  const { selectedCompany } = useSelectedCompany();

  return (
    <trpc.Provider
      client={trpcClient({ selectedCompany })}
      queryClient={client}
    >
      {children}
    </trpc.Provider>
  );
}
