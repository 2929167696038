import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from "@flash-tecnologia/hros-web-utility";
import { MonitoringLevel } from "@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel";

const projectDsn =
  "https://708c42b711e93447a58ee868f3530954@o266934.ingest.us.sentry.io/4508377208258560";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project = projectDsn;

  constructor(props: any) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>,
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }

  static identifyUser(id: string) {
    MonitoringManager.identifyUser(id);
  }

  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error);
  }
}
